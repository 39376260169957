import { Grid } from "@material-ui/core";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  createMenu,
  GetMenuByApp,
  ModifyMenu,
} from "../../../actions/menu.action";
import ControlledSelect from "../../InputForm/ControlledSelect";
import {
  convertPrivilegesToList,
  generateKeyId,
  isEmpty,
} from "../../../utils/proprietaryHooks";
import { MenuSettingsContainer } from "../MenuSettingsContainer";
import { ModalForm } from "../../../views/menuSettings/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { getPrivileges } from "../../../actions/privileges.action";
import FullLoader from "../../Loader/FullLoader.component";
import { UseDialog } from "@dg-bucaramanga/react-components-dg-qa";
import { GET_MENU_CHANGE } from "../../../actions/types";

export let appliUserMenu = "";
export const UserMenuSettings = forwardRef((props, ref) => {
  const { menuLocation, menu, onCancelButton } = props;
  const { setCurrentMenu, idMenu, setCurrentUserDragDrop } = menu;

  const MenuSelectedNumber = 3;
  //#region object definition
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [messageChangeMenu, setMessageChangeMenu] = useState(
    "¡Menú guardado exitosamente!"
  );
  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      application: "",
      state: false,
    },
  });

  /**
   * Valores Iniciales del MenuJson del objeto menu
   */
  const initialValues = {
    name: "",
    privilege: "",
    url: "",
    isActivate: false,
    description: "",
    isFunction: false,
    functionName: "",
    sizeIcon: "24px",
  };

  /*
    id: un número entero que identifica de forma única cada opción de menú.
    name: una cadena de caracteres que representa el nombre de la opción de menú.
    value: un número entero que representa el valor asociado a la opción de menú.
  */
  const menuSectionOptions = [
    { id: 0, name: "Sección Usuario", value: 0 },
    { id: 1, name: "Sección Aplicación", value: 1 },
  ];

  const { control, watch } = form;
  const modalRef = useRef(null);
  const menuSettingRef = useRef(null);
  const getPrivilegesRef = useRef(false)
  const [loading, setLoading] = useState(false);
  const [privilegesList, setPrivilegesList] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [shouldUpdateMenu, setShouldUpdateMenu] = useState(false);
  const [isSaveButton , setIsSaveButton] = useState(false);
  const [isUpdatedItemFlag, setIsUpdatedItemFlag] = useState(false);
  const dispatch = useDispatch();
  const privileges = useSelector(({ privilegesReducer }) => {
    if (!privilegesReducer.getPrivilegesResponse) {
      return [];
    }
    return privilegesReducer.getPrivilegesResponse ?? [];
  });

  const menuChange = useSelector(({ menuReducer }) => {
    return menuReducer.getMenuChangeResponse;
  });

  const [currentApp, setCurrentApp] = useState({
    name: "",
    id: 0,
    items: [],
  });

  const { Dialog, onOpen } = UseDialog({
    bodyText: messageChangeMenu,
  });
  
  useEffect(() => {
    menuSettingRef.current.addInitialDataMenu3({
      application: menu.currentMenu.application,
      user: menu.currentMenu.user,
    });
  }, [menu.currentMenu]);

  useEffect(() => {
    const fetchPrivileges = async () => {
      try {
        setLoading(true);
        await dispatch(getPrivileges(currentApp.name));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        getPrivilegesRef.current = false;
      }
    };
    
    if (currentApp.name !== "" && getPrivilegesRef.current) {
      fetchPrivileges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApp.name]);

  useEffect(() => {
    if (!isEmpty(privileges)) {
      setLoading(false);
      setPrivilegesList(privileges);
    } else {
      setPrivilegesList(privileges);
      setLoading(false);
    }
  }, [privileges]);

  useEffect(() => {
    const currentApp = watch("application");
    appliUserMenu = currentApp;
    if (currentApp && menuSettingRef.current) {
      const appInfo = applications.find((item) => item.id === currentApp);
      if (appInfo) {
        getPrivilegesRef.current = true;
        setIsSaveButton(false);
        setIsUpdatedItemFlag(false);
        dispatch(GetMenuByApp(currentApp, menuLocation));
        setCurrentApp((oldState) => ({ ...oldState, name: appInfo.name }));
        setCurrentMenu({ application: [], user: [] });
        if (menuSettingRef.current) {
          menuSettingRef.current.addInitialDataMenu3({
            application: [],
            user: [],
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("application")]);
  
  useEffect(() => {
    if (!isEmpty(menuChange)) {
      setLoading(false);
      onOpen();
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuChange]);

  useEffect(() => {
    if (shouldUpdateMenu) {
      dispatch(GetMenuByApp(watch("application"), menuLocation));
      setShouldUpdateMenu(false);
    }
  }, [shouldUpdateMenu, dispatch, watch, menuLocation]);

  //#region custom methods

  //CUANDO SE CREA UN NUEVO ITEM EN EL MENU
  const saveItem = (iconName, isPersonalizedIcon, action, itemValues) => {
    setOpen(false);
    const getSelectedPrivilege = () => {
      if (action === "Edit" || action === undefined) {
        const selectedIndex = itemValues.privilege.split(',');
        return selectedIndex || {};
      }
      return {};
    };

    const handleAction = () => {
      const menuValues = menuSettingRef.current.values.menu3;
      const searchValues = {
        edit: {
          ...itemValues,
          iconName,
          isPersonalizedIcon,
          privilege: getSelectedPrivilege(),
        },
        state: { ...itemValues },
        delete: {},
      };
      const arrayOfObject = Object.entries(searchValues[action.toLowerCase()]).sort();
      const orderedObject = Object.fromEntries(arrayOfObject);
      const newMenu = menuSettingRef.current.searchItemUpdateAndDelete(
        itemValues.key,
        menuValues.application,
        action,
        orderedObject
      );
      const newMenu2 = menuSettingRef.current.searchItemUpdateAndDelete(
        itemValues.key,
        menuValues.user,
        action,
        orderedObject
      );
      menuSettingRef.current.addInitialDataMenu3({ 
        application: newMenu,
        user: newMenu2,
      });
      setCurrentMenu({ application: newMenu, user: newMenu2 });
    };

    const addItem = () => {
      const { getValues } = modalRef.current.form;
      const values = getValues();
      const item = {
        application: watch("application"),
        ...values,
        key: generateKeyId(10),
        menuLocation: 3,
        isPersonalizedIcon,
        iconName,
        ...modalRef.current.logo,
      };
      const arrayOfObject = Object.entries(item).sort();
      const orderedObject = Object.fromEntries(arrayOfObject);
      //Linea con problemas
      if (menuSettingRef.current.addItemMenu3) {
        if (orderedObject.section === 0) {
          menuSettingRef.current.addItemMenu3(
            orderedObject,
            "user"
          );
          const previousMenu = [...menu.currentMenu.user, orderedObject];
          setCurrentMenu({ application: [...menu.currentMenu.application], user: previousMenu });
        } else {
          menuSettingRef.current.addItemMenu3(
            orderedObject,
            "application"
          );
          const previousMenuApp = [...menu.currentMenu.application, orderedObject];
          setCurrentMenu({ application: previousMenuApp, user: [...menu.currentMenu.user] });
        }
      }
    };

    if (action) {
      handleAction();
    } else {
      addItem();
    }
    setIsUpdatedItemFlag(true);
    setIsSaveButton(true);
    handleClose();
  };

  //realiza la petición para guardar el menu en el backend
  const saveMenu = async (useHooksData, data, message) => {
    useHooksData = typeof useHooksData !== "boolean" ? false : useHooksData;

    let valuesCompleteApp = useHooksData ? data : menuSettingRef.current.values.menu3;
    let valuesCompleteUser = useHooksData ? data : menuSettingRef.current.values.menu3;

    valuesCompleteApp = convertPrivilegesToList(valuesCompleteApp.application);
    valuesCompleteUser = convertPrivilegesToList(valuesCompleteUser.user);

    let result = {
      application: removeTitle(valuesCompleteApp),
      user: removeTitle(valuesCompleteUser),
    };
    
    if (idMenu === 0) {
      const objectCreate = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(result),
      };
      try {
        await new Promise(createMenu(objectCreate));
      } catch (error) {
        console.error(error);
      }
      if (message !== undefined) {
        setMessageChangeMenu(message);
      } else {
        setMessageChangeMenu("¡Menú guardado exitosamente!");
      }
      onOpen();
      setShouldUpdateMenu(true);
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    } else {
      const objectModify = {
        id: idMenu,
        idapplication: watch("application"),
        idmenulocation: menuLocation,
        numberRows: menuLocation === 4 ? 4 : 0,
        menujson: JSON.stringify(result),
      };
      try {
        await new Promise(ModifyMenu(objectModify));
      } catch (error) {
        console.error(error);
      }
      if (message !== undefined) {
        setMessageChangeMenu(message);
      } else {
        setMessageChangeMenu("¡Menú modificado exitosamente!");
      }
      onOpen();
      setShouldUpdateMenu(true);
      dispatch({
        type: GET_MENU_CHANGE,
        payload: {},
      });
    }
    menuSettingRef.current.addInitialDataMenu3(result);
    setCurrentUserDragDrop({ application: [], user: [] });
    setCurrentMenu(result);
    setIsSaveButton(false);
    setIsUpdatedItemFlag(false);
  };

  /**
   * It takes an array of objects, and returns an array of objects with the title property removed from
   * each object.
   * @returns An array of objects.
   */
  const removeTitle = (menus) => {
    let objectMenus = [];
    if (menus !== undefined && menus.length > 0) {
      for (const menu of menus) {
        let objectMenuChildren = [];
        if (menu.children !== undefined && menu.children.length > 0) {
          objectMenuChildren = removeTitle(menu.children);
        }
        const { title, ...rest } = menu;
        if (objectMenuChildren.length !== 0) {
          objectMenus.push({ ...rest, children: objectMenuChildren });
        } else {
          objectMenus.push(rest);
        }
      }
    }
    return objectMenus;
  };

  const handleClose = () => {
    setInitialFormValues(initialValues);
    modalRef.current.restartModal();
  };

  const cancelButton = () => {
    setIsUpdatedItemFlag(false);
    setIsSaveButton(false);
    onCancelButton(menuLocation);
  };

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <FullLoader open={loading} />
      ) : (
        <div>
          <br/>
          <Grid container>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <ControlledSelect
                control={control}
                id="application"
                name="application"
                options={applications}
                label="Aplicación"
              />
            </Grid>
          </Grid>
          <MenuSettingsContainer
            saveMenu={saveMenu}
            applications={applications}
            setApplications={setApplications}
            currentApp={currentApp}
            setCurrentApp={setCurrentApp}
            setInitialFormValues={setInitialFormValues}
            menu={menu}
            menuLocation={menuLocation}
            MenuSelected={MenuSelectedNumber}
            form={form}
            setOpen={setOpen}
            ref={menuSettingRef}
            areChangesSave={isSaveButton}
            onCancelButton={cancelButton}
            setChangesSave={setIsSaveButton}
			      setCurrentUserDragDrop={setCurrentUserDragDrop}
            isUpdatedItemFlag={isUpdatedItemFlag}
          />
          <ModalForm
            section={menuSectionOptions}
            initialValues={initialFormValues}
            ref={modalRef}
            open={open}
            setOpen={setOpen}
            handleSave={saveItem}
            handleClose={handleClose}
            appName={currentApp}
            privileges={privilegesList}
          />
          <Dialog />
          <FullLoader open={loading} />
        </div>
      )}
    </div>
  );
});
